export const name = "WNRS Cann Expansion Pack"
export const menu = "Cann"
export const crossover = "Cann"
export const suggestedPlayer = "2 players"
export const color = {
    primary: {
        main:  '#ecd2e1',
        contrastText: '#00a6c7',
    },
    secondary: {
        main: '#00a6c7',
    }
}
export const levels = ['Level 1 Perception', 'Level 2 Connection', 'Level 3 Reflection']
export const isExpansion = true

export const backDesc = [ 
    "Let's get even closer"
  ]
  
  export const instruction = [
  ]

export const level1 = [
    'On a scale of 1-10, how high do you think I am?',
    'What\'s something you\'ve never noticed about me until this moment?',
    'If I were a song, which song would I be?',
]

export const level2 = [
    'What\'s the most embarrassed you remember being from something you did while drunk?',
    'If you could have any food in the world right now, what would it be?',
    'How would you describe the feeling of drinking a Cann in one word?',
    'What do you wish you could see\n with a brand new perspective?', //
    'What do you want to spend more of your time doing? What do you want to spend less of your time doing?',
    'What\'s been hard for you to say out loud? Get it off your chest.',
    'What are you most grateful for in this current moment?',
    'What idea of yours do you feel most excited about?', //
    'What would bring your life\n 5% more happiness?',
    'Who in your life do you need to set better boundaries with?',
    'What have you been feeling a lot of recently?',
    'What do you need right now but aren\'t communicating?',
    'What does "taking care of you" look like in this chapter of your life?',
    'Wild Card Staring contest. First one to laugh has to finish their Cann.',
    'Wild Card What\'s your favorite guilty pleasure? On the count of three, say your answers out loud.',
    'Wild Card Pitch me a million dollar idea.',
    'Wild Card Make the weirdest noise you can. Both players.',
    'Wild Card Share a video that always you laugh with your partner. (Both players)',
    'Wild Card Describe the best party you\'ve been to recently in a different accent.',
    'Wild Card Draw a snapshot of your dream vacation.',
    'Wild Card Write a rap verse that describes your current love life & perform it. Get into it!',
]

export const level3 = [
    'Wild Card What\'s your favorite pick-me-up? On the count of three say your answers out loud.',
]